import { SET_THEME } from "../actions/themeActions"
import initialState from "./initialState"

export default function themeReducer(state = initialState.theme, action) {
  switch (action.type) {
    case SET_THEME:
      return { ...state, ...action.status }
    default:
      return state
  }
}
