import { SET_LANGUAGE } from "../actions/languageActions"
import initialState from "./initialState"

export default function languageReducer(state = initialState.language, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.language
    default:
      return state
  }
}
