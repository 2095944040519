import { createStore } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import rootReducer from "./reducers"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import initialState from "./reducers/initialState"
const persistConfig = {
  key: "root",
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer, initialState)
export const persistor = persistStore(store)
