import { SET_OPEN } from "../actions/menuActions"
import initialState from "./initialState"

export default function menuReducer(state = initialState.menu, action) {
  switch (action.type) {
    case SET_OPEN:
      return { ...state, isOpen: action.status }
    default:
      return state
  }
}
