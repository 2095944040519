import { combineReducers } from "redux"
import language from "./languageReducer"
import menu from "./menuReducer"
import theme from "./themeReducer"

const rootReducer = combineReducers({
  language,
  menu,
  theme,
})

export default rootReducer
