// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-us-page-js": () => import("./../../../src/templates/aboutUsPage.js" /* webpackChunkName: "component---src-templates-about-us-page-js" */),
  "component---src-templates-disclaimer-page-js": () => import("./../../../src/templates/disclaimerPage.js" /* webpackChunkName: "component---src-templates-disclaimer-page-js" */),
  "component---src-templates-esports-betting-page-js": () => import("./../../../src/templates/esportsBettingPage.js" /* webpackChunkName: "component---src-templates-esports-betting-page-js" */),
  "component---src-templates-esports-betting-subcategory-js": () => import("./../../../src/templates/esportsBettingSubcategory.js" /* webpackChunkName: "component---src-templates-esports-betting-subcategory-js" */),
  "component---src-templates-glossary-page-js": () => import("./../../../src/templates/glossaryPage.js" /* webpackChunkName: "component---src-templates-glossary-page-js" */),
  "component---src-templates-integrity-policy-page-js": () => import("./../../../src/templates/integrityPolicyPage.js" /* webpackChunkName: "component---src-templates-integrity-policy-page-js" */),
  "component---src-templates-mobile-casino-page-js": () => import("./../../../src/templates/mobileCasinoPage.js" /* webpackChunkName: "component---src-templates-mobile-casino-page-js" */),
  "component---src-templates-mobile-casino-subcategory-js": () => import("./../../../src/templates/mobileCasinoSubcategory.js" /* webpackChunkName: "component---src-templates-mobile-casino-subcategory-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/newsPage.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-online-casino-page-js": () => import("./../../../src/templates/onlineCasinoPage.js" /* webpackChunkName: "component---src-templates-online-casino-page-js" */),
  "component---src-templates-online-casino-subcategory-js": () => import("./../../../src/templates/onlineCasinoSubcategory.js" /* webpackChunkName: "component---src-templates-online-casino-subcategory-js" */),
  "component---src-templates-sports-betting-page-js": () => import("./../../../src/templates/sportsBettingPage.js" /* webpackChunkName: "component---src-templates-sports-betting-page-js" */),
  "component---src-templates-sports-betting-subcategory-js": () => import("./../../../src/templates/sportsBettingSubcategory.js" /* webpackChunkName: "component---src-templates-sports-betting-subcategory-js" */),
  "component---src-templates-start-page-js": () => import("./../../../src/templates/startPage.js" /* webpackChunkName: "component---src-templates-start-page-js" */)
}

