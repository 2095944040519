import React from "react"
import { Provider } from "react-redux"
import { store, persistor } from "./configureStore"

import { PersistGate } from "redux-persist/integration/react"

export default ({ element }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {element}
    </PersistGate>
  </Provider>
)
